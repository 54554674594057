import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'src/store';
import SubscribeButton from 'src/components/pricing/SubscribeButton';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

interface Props {
    isPremium: boolean | undefined;
}

const Basic: React.FC<Props> = ({ isPremium }) => {
    const isLoggedIn = useSelector((state: AppState) => state.auth.isLoggedIn);
    const navigate = useNavigate();

    const login = () => {
        navigate('/logowanie');
    };

    const navigateToProfile = () => {
        if (isLoggedIn && !isPremium) {
            navigate('/profil');
        }
        return null;
    };

    const isActive = () => {
        return isLoggedIn && !isPremium;
    };

    return (
        <div className="grid gap-y-3.5">
            <div className="">
                <div className="grid grid-cols-pricing items-center gap-x-2">
                    <span className="font-bold text-2xl">0 zł</span>
                    <span className="self-end text-black-400">/ miesiąc</span>
                </div>
                <span className="text-gray-500 font-light text-sm">Za darmo na zawsze</span>
            </div>
            <div
                className={cx(
                    {
                        'mb-18': isLoggedIn
                    },
                    isActive()
                        ? 'blue-box-shadow'
                        : 'border-solid border rounded-lg border-light-gray',
                    ' relative p-3 text-center'
                    // border-solid border rounded-lg border-light-gray
                )}
                style={{ height: '52px' }}
                onClick={navigateToProfile}
            >
                <label>
                    <span
                        className={cx(
                            { ' text-blue-500 font-bold text-lg': isActive() },
                            { 'text-black-400 text-small': !isActive() }
                        )}
                    >
                        {!isPremium && isLoggedIn ? 'Aktualny plan' : 'Darmowy plan'}
                    </span>
                </label>
            </div>
            {/*<GrayBox text="Darmowy plan" classNames={isLoggedIn ? 'mb-18' : ''} />*/}
            {!isLoggedIn && <SubscribeButton text="Zaloguj się" onClick={login} />}
        </div>
    );
};

export default Basic;

import React, { useEffect, useState } from 'react';
import SubscribeButton from 'src/components/pricing/SubscribeButton';
import PricingSelect from 'src/components/pricing/PricingSelect';
import { Price } from 'src/interfaces/Pricing';
import { useFormik } from 'formik';
import { convertFromPenny } from 'src/helpers/utils';
import { useNavigate } from 'react-router-dom';
import { useSubscribe } from 'src/hooks/react-query/pricingQueries';
import { FetchMeResponse } from 'src/interfaces/auth/FetchMeInterfaces';
import cx from 'classnames';

interface PremiumProps {
    prices: Price[];
    pricingBadgeText?: string;
    me: FetchMeResponse | undefined;
}

const Premium: React.FC<PremiumProps> = ({ prices, pricingBadgeText, me }) => {
    const navigate = useNavigate();
    const subscribe = useSubscribe();
    const [selectedPricing, setSelectedPricing] = useState<Price>(prices[0]);

    const formik = useFormik({
        initialValues: {
            price_id: prices[0].id
        },
        onSubmit: async (values) => {
            if (localStorage.getItem('GW_TOKEN')) {
                await subscribe.mutateAsync({ price_id: values.price_id });
            } else {
                navigate(`/logowanie?priceId=${values.price_id}`);
            }
        },
        enableReinitialize: true
    });

    useEffect(() => {
        const selected = prices.find((price) => price.id === formik.values.price_id);
        if (selected) {
            setSelectedPricing(selected);
        }
    }, [formik.values.price_id]);

    const pricePerInterval = (price: number, interval: number) => {
        if (interval !== 0) {
            return price / interval;
        }
        return price;
    };

    const navigateToProfile = () => {
        navigate('/profil');
    };

    return (
        <>
            <div>
                <div className="grid grid-cols-pricing items-center gap-x-2">
                    <span className="font-bold text-2xl">
                        {convertFromPenny(
                            pricePerInterval(selectedPricing.price, selectedPricing.interval)
                        ).toFixed(2)}{' '}
                        zł
                    </span>
                    <span className="self-end text-black-400">
                        {/*/ {selectedPricing.interval}*/}/ miesiąc
                    </span>
                    {pricingBadgeText && (
                        <div
                            className="
                                    self-end
                                    justify-self-start
                                    grid-row-start-1
                                    grid-row-end-1
                                    grid-col-start-2
                                    uppercase
                                    inline-flex
                                    items-center
                                    justify-center
                                    rounded
                                    text-white
                                    text-sm
                                    font-semibold
                                    rounded-2xl
                                    px-2
                                    py-1
                                    bg-primary-400
                                    "
                        >
                            {pricingBadgeText}
                        </div>
                    )}
                </div>
                <span className="text-gray-500 font-light text-sm">
                    {/*{selectedPricing.description*/}
                    {/*    ? selectedPricing.description*/}
                    {/*    : `${convertFromPenny(selectedPricing.price).toFixed(2)} ${*/}
                    {/*          selectedPricing.currency*/}
                    {/*      } za cały okres`}*/}
                    Możesz anulować w dowolnym momencie.
                </span>
            </div>
            {me?.is_premium ? (
                <div
                    onClick={navigateToProfile}
                    className={cx(
                        'cursor-pointer mb-18 relative rounded-lg blue-box-shadow p-3 text-center'
                    )}
                    style={{ height: '52px' }}
                >
                    <label>
                        <span className={cx('cursor-pointer text-blue-500 font-bold text-lg')}>
                            Aktualny plan
                        </span>
                    </label>
                </div>
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid gap-y-3.5">
                        <PricingSelect
                            label="Okres płatności:"
                            options={prices}
                            selectedPricing={selectedPricing}
                            handleChange={formik.handleChange}
                        />
                        <SubscribeButton text="Wypróbuj 7 dni za darmo" />
                    </div>
                </form>
            )}
        </>
    );
};

export default Premium;

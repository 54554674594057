import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { RegisterRequest, RegisterResponse } from 'src/interfaces/auth/RegisterInterfaces';
import { authApi } from 'src/api/api';
import { toast } from 'react-toastify';
import { LoginRequest, LoginResponse } from 'src/interfaces/auth/LoginInterfaces';
import { RefreshTokenResponse } from 'src/interfaces/auth/RefreshTokenInterfaces';

export const useRegister = (): UseMutationResult<
    AxiosResponse<RegisterResponse>,
    AxiosError,
    RegisterRequest
> => {
    return useMutation(async (registerData) => await authApi.register(registerData), {
        onSuccess: (data) => {
            toast.success('Rejestracja przebiegła pomyślnie');
        }
    });
};

export const useLogin = (): UseMutationResult<
    AxiosResponse<LoginResponse>,
    AxiosError,
    LoginRequest
> => {
    return useMutation(async (loginData) => {
        return await authApi.login(loginData);
    });
};

export const useRefreshToken = (): UseMutationResult<
    AxiosResponse<RefreshTokenResponse>,
    AxiosError
> => {
    return useMutation(async () => {
        return await authApi.refreshToken();
    });
};

export const useForgotPassword = (): UseMutationResult<AxiosResponse<any>, AxiosError, any> => {
    return useMutation(async (forgotPasswordEmail) => {
        return await authApi.forgotPassword({ email: forgotPasswordEmail });
    });
};

export const useResetPassword = (): UseMutationResult<AxiosResponse<any>, AxiosError, any> => {
    return useMutation(
        async (data) => {
            return await authApi.resetPassword(data);
        },
        {
            onSuccess: () => {
                toast.success('Hasło zostało zmienione.');
            }
        }
    );
};

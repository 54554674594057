import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AuthState = { isLoggedIn: boolean };

export const loginSlice = createSlice({
    name: 'auth',
    initialState: {
        isLoggedIn: false
    } as AuthState,

    reducers: {
        setLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload;
        }
    }
});

export const { setLoggedIn } = loginSlice.actions;

export default loginSlice.reducer;

import React from 'react';
import { useNavigate } from 'react-router-dom';

const PostInstallation: React.FC = () => {
    const navigate = useNavigate();

    const navigateToRegistration = () => {
        navigate('/rejestracja');
    };
    return (
        <div className="thank-you-container">
            <div className="custom-container custom-container--thank-you flex flex-1">
                <div className="page-container">
                    <div className="card card--big py-10 px-10">
                        <span className="card__header text-center">Witaj w GoodWrite</span>
                        <span className="mt-10 text-blueGray-600">
                            To rozszerzenie sprawdzi Twój tekst, wysyłając go do Goodwrtie za pomocą
                            zaszyfrowanego połaczenia.{' '}
                            <strong>Twój tekst nie będzie nigdzie zapisany. </strong>
                            <br />
                            Sprawdzenie można wyłączyc w każdym momencie.
                        </span>
                        <span>
                            <ul className="options-list">
                                <li className="relative text-blueGray-600">
                                    <strong>Twój tekst nie będzie nigdy zapisany.</strong>
                                </li>
                                <li className="relative text-blueGray-600">
                                    Sprawdzenie można <strong>wyłączyć w dowolnym momencie.</strong>
                                </li>
                            </ul>
                        </span>

                        <button
                            className="mt-7 form-submit-button self-center"
                            onClick={navigateToRegistration}
                        >
                            Rozumiem
                        </button>
                    </div>
                </div>
                <div className="bg-image bg-image--thank-you" />
            </div>
        </div>
    );
};

export default PostInstallation;

import { ReactText } from 'react';
// @ts-ignore
import swal from 'sweetalert2';

export const convertFromPenny = (price: ReactText): number => {
    const floatPrice = parseFloat(price as string);
    if (isFinite(floatPrice)) {
        return floatPrice / 100;
    }
    return 0;
};

export const renderBasicSwal = (
    title: string,
    description: string,
    type: 'success' | 'error'
): void => {
    swal.fire({
        title: title,
        text: description,
        confirmButtonColor: '#8CD4F5',
        showConfirmButton: type === 'success',
        showDenyButton: type === 'error',
        denyButtonText: 'Ok',
        customClass: {
            denyButton: 'sweet-gw sweet-gw__deny',
            confirmButton: 'sweet-gw sweet-gw__confirm'
        }
    });
};

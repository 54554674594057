import React from 'react';
import SubscribeButton from 'src/components/pricing/SubscribeButton';

interface ForTeamsProps {
    header: string;
}

const ForTeams: React.FC<ForTeamsProps> = ({ header }) => {
    const openMailTo = () => {
        window.open('mailto:kontakt@goodwrite.pl');
    };

    return (
        <div className="grid gap-y-3.5">
            <span className="font-bold text-2xl mb-6 text-left">{header}</span>

            {/*<GrayBox text="Tylko płatność roczna" />*/}
            <div className="relative border-solid border rounded-lg border-light-gray p-3 text-center">
                <label>
                    <span className="text-black-400 text-small pr-3">Roczny okres płatności</span>
                </label>
            </div>
            <div className="grid gap-x-3 items-center">
                <SubscribeButton text="Skontaktuj się" onClick={openMailTo} />
            </div>
        </div>
    );
};

export default ForTeams;

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useResetPassword } from 'src/hooks/react-query/authQueries';
import { ResetPassSchema } from 'src/consts/schemas';
import cx from 'classnames';
import TextInput from 'src/components/forms/TextInput';
import Spinner from 'src/components/Spinner';

const ResetPasswordPage = () => {
    const { token } = useParams<{ token: any }>();
    const resetPassword = useResetPassword();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        onSubmit: async (values) => {
            try {
                await resetPassword.mutateAsync({
                    token: token,
                    password: formik.values.password
                });
                navigate('/');
            } catch (error) {
                formik.setStatus({ _form: 'error' });
            }
        },
        validationSchema: ResetPassSchema,
        validateOnBlur: true,
        validateOnChange: false,
        enableReinitialize: true
    });

    const isFormError = () => {
        if (formik.status && formik.status._form && formik.status._form === 'error') {
            return true;
        }
        return false;
    };
    return (
        <div className="h-full flex py-10 px-4 justify-center">
            <div className="custom-container flex flex-1">
                <div className="page-container">
                    <div
                        className={cx('card card--login', {
                            'card--login-error': isFormError()
                        })}
                    >
                        <div className="rounded-t mb-0 px-4 py-10">
                            <div className="text-center">
                                <span className="card__header">Resetowanie hasła</span>
                            </div>
                            <div className="flex text-blueGray-600 text-center mt-10 font__siginika">
                                Utwórz nowe, silne hasło, którego nie używasz na innych stronach.
                            </div>
                        </div>

                        <div className="flex-auto px-10 pb-10">
                            {' '}
                            <form
                                className="flex items-center flex-col"
                                onSubmit={formik.handleSubmit}
                            >
                                <div className="w-full flex-1 flex flex-col ">
                                    <TextInput
                                        value={formik.values.password}
                                        id={'password'}
                                        type={'password'}
                                        placeholder={'Wpisz hasło'}
                                        name={'password'}
                                        handleChange={formik.handleChange}
                                        isError={!!formik.errors.password || isFormError()}
                                        errorMessage={formik.errors.password}
                                        classnames="mt-1"
                                    />
                                    <TextInput
                                        value={formik.values.confirmPassword}
                                        id={'confirmPassword'}
                                        type={'password'}
                                        placeholder={'Potwierdź hasło'}
                                        name={'confirmPassword'}
                                        handleChange={formik.handleChange}
                                        isError={!!formik.errors.confirmPassword || isFormError()}
                                        errorMessage={formik.errors.confirmPassword}
                                        classnames="mt-7"
                                    />
                                </div>
                                {isFormError() && (
                                    <div className="w-full flex-1 flex-col mb-3">
                                        <span className="text-danger">Houston, mamy problem!</span>
                                        <ul className="list-inside list-disc text-danger">
                                            <li>Sprawdź, czy wpisałeś takie same hasła</li>
                                            <li>Sprawdź, czy hasła zawierają 8 znaków</li>
                                        </ul>
                                    </div>
                                )}
                                <button
                                    className="form-submit-button mt-7"
                                    type="submit"
                                    disabled={resetPassword.isLoading}
                                >
                                    {resetPassword.isLoading ? <Spinner /> : <span>Potwierdź</span>}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="bg-image bg-image--login" />
            </div>
        </div>
    );
};

export default ResetPasswordPage;

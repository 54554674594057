import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { pricingApi } from 'src/api/api';
import { FetchMyPlanResponse, Products, SubscriptionRequest } from 'src/interfaces/Pricing';

export const useFetchProducts = (): UseQueryResult<Products[], AxiosError> => {
    return useQuery(
        ['products'],
        async () => {
            const response = await pricingApi.fetchProducts();
            return response.data;
        },
        {
            keepPreviousData: true,
            staleTime: Infinity,
            retry: false
        }
    );
};

export const useFetchMyPlan = (): UseMutationResult<
    AxiosResponse<FetchMyPlanResponse>,
    AxiosError
> => {
    return useMutation(
        async () => {
            return await pricingApi.fetchMyPlan();
        },
        {
            onSuccess: (response) => {
                window.open(response.data.redirect_url);
            }
        }
    );
    // return useQuery(
    //     ['plan'],
    //     async () => {
    //         const response = await pricingApi.fetchMyPlan();
    //         return response.data;
    //     },
    //     {
    //         keepPreviousData: true,
    //         staleTime: Infinity,
    //         retry: false
    //     }
    // );
};

export const useSubscribe = (): UseMutationResult<
    AxiosResponse,
    AxiosError,
    SubscriptionRequest
> => {
    return useMutation(
        async (pricingData) => {
            return await pricingApi.subscribe(pricingData.price_id);
        },
        {
            onSuccess: (response) => {
                window.open(response.data.redirect_url);
            }
        }
    );
};

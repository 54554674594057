import React from 'react';

import { useFetchProducts } from 'src/hooks/react-query/pricingQueries';
import CardDeck from 'src/pages/LandingPage/CardDeck';
import LoadingPricingCard from 'src/pages/LandingPage/LoadingPricingCard';
import AnimatedText from 'src/components/pricing/AnimatedText';
import { UseQueryResult } from 'react-query';
import { Products } from 'src/interfaces/Pricing';
import { AxiosError } from 'axios';

const renderContent = (products: UseQueryResult<Products[], AxiosError<any>>) => {
    if (products.isError) {
        return <div>Error</div>;
    }
    if (products.isSuccess) {
        return <CardDeck products={products.data} />;
    }
    if (products.isLoading) {
        return (
            <>
                <LoadingPricingCard />
                <LoadingPricingCard />
                <LoadingPricingCard />
            </>
        );
    }
};

const LandingPage: React.FC = () => {
    const products = useFetchProducts();

    return (
        <div className="main-container">
            <AnimatedText />
            <div className="card-deck items-start justify-center">{renderContent(products)}</div>
        </div>
    );
};

export default LandingPage;

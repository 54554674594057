import { LOCAL_STORAGE } from 'src/consts/consts';

export const setToken = (token: string): void => {
    localStorage.setItem(LOCAL_STORAGE.token, token);
};

export const removeToken = (): void => {
    localStorage.removeItem(LOCAL_STORAGE.token);
};

export const getToken = (): string | null => {
    return localStorage.getItem(LOCAL_STORAGE.token);
};

import React, { useState } from 'react';
import useInterval from 'src/hooks/useInterval';

const wordSet: string[] = [
    'lepsze',
    'bezbłędne',
    'poprawne',
    'zrozumiałe',
    'skuteczne',
    'oryginalne',
    'kreatywne'
];

const AnimatedText: React.FC = () => {
    const [word, setWord] = useState(wordSet[0]);
    const [nextWord, setNextWord] = useState(wordSet[1]);

    useInterval(() => {
        let index = wordSet.indexOf(word);

        if (index + 2 >= wordSet.length) {
            if (index % 2 === 0) {
                index = 1;
            } else {
                index = 0;
            }
            setWord(() => wordSet[index]);
        } else {
            setWord(() => wordSet[index + 2]);
        }
    }, 4000);

    useInterval(() => {
        setTimeout(() => {
            let index = wordSet.indexOf(nextWord);
            if (index + 2 >= wordSet.length) {
                if (index % 2 === 0) {
                    index = 1;
                } else {
                    index = 0;
                }
                setNextWord(() => wordSet[index]);
            } else {
                setNextWord(() => wordSet[index + 2]);
            }
        }, 2000);
    }, 4000);

    return (
        <div className="header ">
            <span className="text-4xl text-black-400 text-center">
                Spraw, by Twoje teksty były{' '}
            </span>
            <div className="animation-container">
                <div className="text-animated-one text-center ">
                    <span className="text-3xl">{word}</span>
                </div>
                <div className="text-animated-two text-center">
                    <span className="text-3xl">{nextWord}</span>
                </div>
            </div>
        </div>
    );
};

export default AnimatedText;

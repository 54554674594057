import React from 'react';
import logo from '../../assets/images/logo.png';
import cx from 'classnames';
import { BADGE_TYPE } from 'src/helpers/consts';

interface PricingCard {
    header: string;
    logoBadgeText: BADGE_TYPE;
    headerCaption: string;
    options: { text: string; isBold: boolean; options?: string[]; comingSoon?: boolean }[];
    isActive: boolean;
}

const renderBadge = (type: BADGE_TYPE) => {
    if (type === BADGE_TYPE.PREMIUM) {
        return (
            <div
                className="
                          self-center
                          bg-gradient-to-r
                          from-blue-premium-500
                          to-violet-premium-500
                          uppercase
                          inline-flex
                          items-center
                          justify-center
                          rounded
                          badge badge__small"
            >
                <p className="text-xxs text-center badge__text">Premium</p>
            </div>
        );
    } else {
        return (
            <div
                className="
                            grid-row-start-1
                            grid-row-end-1
                            grid-col-start-2
                            uppercase
                            inline-flex
                            items-center
                            justify-center
                            rounded
                            text-white
                            rounded
                            p-1
                            badge badge__small"
            >
                <p className="text-xxs text-center badge__text">Basic</p>
            </div>
        );
    }
};

const renderComingSoonBadge = () => {
    return <span className="coming-soon-badge">Wkrótce</span>;
};

const PricingCard: React.FC<PricingCard> = ({
    children,
    header,
    headerCaption,
    logoBadgeText,
    options,
    isActive
}) => {
    const renderItemOptions = (options: string[]) => {
        return (
            <ul className="nested-list">
                {options.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
        );
    };

    return (
        <div className={cx('pricing-card', { 'pricing-card--active': isActive })}>
            <div className="pricing-card__header">
                <div className="grid grid-cols-pricing-logo items-center gap-x-3">
                    <img src={logo} alt={'logo'} className="w-10 h-10" />
                    {renderBadge(logoBadgeText)}
                </div>
                <span className="text-xl text-blue-500 font-semibold mt-2.5">{header}</span>
                <span className="text-gray-500 font-light text-sm">{headerCaption}</span>
            </div>
            <div className="pricing-card__content">{children}</div>
            <div className="pricing-card__footer">
                <ul className="options-list">
                    {options.map((item, index) => {
                        return (
                            <li
                                className={cx('relative', { 'font-bold': item.isBold })}
                                key={index}
                            >
                                <span className="mr-3">{item.text}</span>
                                {item.options && renderItemOptions(item.options)}
                                {item.comingSoon && renderComingSoonBadge()}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default PricingCard;

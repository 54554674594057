import client from 'src/api/client';
import { AxiosResponse } from 'axios';

import { RegisterRequest, RegisterResponse } from 'src/interfaces/auth/RegisterInterfaces';
import { LoginRequest, LoginResponse } from 'src/interfaces/auth/LoginInterfaces';
import { RefreshTokenResponse } from 'src/interfaces/auth/RefreshTokenInterfaces';
import { FetchMeResponse } from 'src/interfaces/auth/FetchMeInterfaces';
import { FetchMyPlanResponse, Products } from 'src/interfaces/Pricing';

export const authApi = {
    login: (data: LoginRequest): Promise<AxiosResponse<LoginResponse>> => {
        const formData = new FormData();
        formData.append('username', data.username);
        formData.append('password', data.password);
        return client.post('/auth/jwt/login', formData);
    },
    register: async (data: RegisterRequest): Promise<AxiosResponse<RegisterResponse>> => {
        try {
            // if (data.newsletter) {
            //     const t = await client.get(
            //         'https://api.mailerlite.com/api/v2/groups?limit=100&offset=0&filters=null',
            //         {
            //             headers: {
            //                 'x-mailerlite-apikey': '13619ce7de3f66ccfe08b2279cd697d8'
            //             }
            //         }
            //     );
            // }
            return client.post('/auth/register', data);
        } catch (err) {
            return err;
        }
    },
    refreshToken: (): Promise<AxiosResponse<RefreshTokenResponse>> => {
        return client.get('/auth/jwt/refresh/');
    },
    forgotPassword: (data: any) => {
        return client.post('/auth/forgot-password', data);
    },
    resetPassword: (data: any) => {
        return client.post('/auth/reset-password', data);
    }
};

export const userApi = {
    fetchMe: (): Promise<AxiosResponse<FetchMeResponse>> => {
        return client.get('/users/me/');
    }
};

export const pricingApi = {
    fetchProducts: (): Promise<AxiosResponse<Products>> => {
        return client.get('/payment/products/');
    },
    fetchMyPlan: (): Promise<AxiosResponse<FetchMyPlanResponse>> => {
        return client.post('/payment/my_plan/');
    },
    subscribe: async (id: string | number): Promise<any> => {
        return client.post('/payment/subscribe/', { price_id: id });
    }
};

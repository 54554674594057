import React from 'react';
import { Link } from 'react-router-dom';

const ConfirmEmailPage: React.FC = () => {
    return (
        <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
                <div className="w-full lg:w-6/12 px-4">
                    <div className="border-lightgray relative flex flex-col min-w-0 break-words w-full mb-6 shadow-2xl rounded-lg bg-white border-gray">
                        <div className="rounded-t mb-0 px-6 py-6">
                            <div className="text-center font__siginika">
                                <div className="text-blueGray-600 text-center mt-3 font-bold">
                                    Udało ci się zarejestrować!
                                </div>
                                <Link
                                    className="font-primary font-bold cursor-pointer"
                                    to={'/logowanie'}
                                >
                                    Zaloguj się
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmEmailPage;

import React from 'react';

interface SubscribeButton {
    text: string;
    onClick?: () => void;
}

const SubscribeButton: React.FC<SubscribeButton> = ({ text, onClick }) => {
    return (
        <button
            className="text-base
            font-bold
            bg-primary-600
            text-white
            active:bg-blueGray-600
            font-bold
            px-6 py-3.5
            rounded shadow hover:shadow-lg outline-none
            focus:outline-none
            mr-1 mb-1 w-full ease-linear transition-all duration-150
            "
            type="submit"
            onClick={onClick}
        >
            {text}
        </button>
    );
};

export default SubscribeButton;

import React from 'react';
import { FormikHandlers } from 'formik';
import cx from 'classnames';

interface Props {
    labelClassName?: string;
    placeholder: string;
    name: string;
    handleChange: FormikHandlers['handleChange'];
    type: string;
    id: string;
    classnames?: string;
    isError: boolean;
    errorMessage: string | undefined;
    value: string;
}

const TextInput: React.FC<Props> = ({
    name,
    id,
    type,
    placeholder,
    handleChange,
    classnames,
    isError,
    errorMessage,
    value
}) => {
    return (
        <div className={cx(['w-full relative', classnames])}>
            <input
                name={name}
                id={id}
                type={type}
                className={cx([
                    { 'border-error': isError },
                    'px-3 py-3 bg-white rounded w-full ease-linear focus:outline-none border-2 border-gray'
                ])}
                value={value}
                autoComplete={name}
                onChange={handleChange}
            />
            {placeholder && <label className="">{placeholder}</label>}
            {errorMessage && (
                <small className="text-red-500 inline-block ml-1">{errorMessage}</small>
            )}
        </div>
    );
};

export default TextInput;

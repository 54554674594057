import React from 'react';

const LoadingProfile: React.FC = () => {
    return (
        <div className="h-full flex py-10 px-4 justify-center">
            <div className="custom-container flex flex-1 items-center">
                <div className="profile-container profile-container--loading">
                    <div className="flex justify-center container mx-auto h-full gap-y-5">
                        <div className="animate-pulse">
                            <div className="account flex-grow border-lightgray relative flex flex-col min-w-0 break-words w-full rounded-lg bg-white border-gray">
                                <div className="grid justify-items-start gap-x-3.5 gap-y-3.5">
                                    <div className="h-4 bg-blue-400 rounded w-5/6 justify-self-center"></div>
                                    <div className="h-4 bg-blue-400 rounded w-4/6"></div>
                                    <div className="h-4 bg-blue-400 rounded w-4/6"></div>
                                    <div className="h-4 bg-blue-400 rounded w-4/6"></div>
                                    <div className="h-4 bg-blue-400 rounded w-4/6"></div>
                                    <div className="h-4 bg-blue-400 rounded w-3/6"></div>
                                    <div className="h-4 bg-blue-400 rounded w-3/6"></div>
                                    <div className="w-5/6 flex justify-self-center">
                                        <div className="h-4 bg-blue-400 rounded w-3/6 mr-3"></div>
                                        <div className="h-4 bg-blue-400 rounded w-3/6 mr-3"></div>
                                        <div className="h-4 bg-blue-400 rounded w-3/6 mr-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-image bg-image--profile" />
            </div>
        </div>
    );
};

export default LoadingProfile;

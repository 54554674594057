import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';

import loginReducer from 'src/store/slices/authSlice';

const rootReducer = combineReducers({
    auth: loginReducer
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware()]
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

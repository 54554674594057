import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { LoginSchema } from 'src/consts/schemas';
import { useLogin } from 'src/hooks/react-query/authQueries';
import { LoginRequest } from 'src/interfaces/auth/LoginInterfaces';
import { setToken } from 'src/helpers/token';
import { setLoggedIn } from 'src/store/slices/authSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/store';
import TextInput from 'src/components/forms/TextInput';
import cx from 'classnames';
import Spinner from 'src/components/Spinner';
import { useSubscribe } from 'src/hooks/react-query/pricingQueries';

const LoginPage: React.FC = () => {
    const login = useLogin();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const subscribe = useSubscribe();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        initialErrors: {
            email: '',
            password: ''
        },
        onSubmit: async ({ email, password }) => {
            const data: LoginRequest = {
                username: email,
                password
            };
            formik.setStatus({ _form: '' });
            try {
                const response = await login.mutateAsync(data);
                setToken(response.data.access_token);
                dispatch(setLoggedIn(true));

                const priceIdFromUrl = searchParams.get('priceId');

                if (priceIdFromUrl) {
                    await subscribe.mutateAsync({ price_id: priceIdFromUrl });
                }
                navigate('/');
            } catch (error) {
                if (typeof error.response.data.detail === 'string') {
                    switch (error.response.data.detail) {
                        case 'LOGIN_BAD_CREDENTIALS':
                            formik.setStatus({ _form: 'error' });
                            break;
                        default:
                            formik.setStatus({ _form: 'error' });
                            break;
                    }
                }
                dispatch(setLoggedIn(false));
            }
        },
        validationSchema: LoginSchema,
        validateOnBlur: true,
        validateOnChange: false,
        enableReinitialize: true
    });

    const isFormError = () => {
        if (formik.status && formik.status._form && formik.status._form === 'error') {
            return true;
        }
        return false;
    };

    return (
        <div className="h-full flex py-48 px-4 justify-center">
            <div className="custom-container flex flex-1">
                <div className="page-container">
                    <div
                        className={cx('card card--login', {
                            'card--login-error': isFormError()
                        })}
                    >
                        <div className="rounded-t mb-0 px-4 py-10">
                            <div className="text-center">
                                <span className="card__header">Logowanie do panelu</span>
                            </div>
                        </div>

                        <div className="flex-auto px-10 pb-10">
                            <form
                                onSubmit={formik.handleSubmit}
                                className="flex items-center flex-col"
                            >
                                <div className="w-full flex-1 flex flex-col ">
                                    <TextInput
                                        value={formik.values['email']}
                                        id="email"
                                        type={'email'}
                                        placeholder={'Wpisz e-mail'}
                                        name={'email'}
                                        handleChange={formik.handleChange}
                                        isError={!!formik.errors.email || isFormError()}
                                        errorMessage={formik.errors.email}
                                    />
                                    <TextInput
                                        value={formik.values['password']}
                                        id={'password'}
                                        type={'password'}
                                        placeholder={'Wpisz hasło'}
                                        name={'password'}
                                        handleChange={formik.handleChange}
                                        isError={!!formik.errors.email || isFormError()}
                                        errorMessage={formik.errors.password}
                                        classnames="mt-7"
                                    />
                                </div>
                                {isFormError() && (
                                    <div className="w-full flex-1 flex-col mb-3">
                                        <span className="text-danger">Houston, mamy problem!</span>
                                        <ul className="list-inside list-disc text-danger">
                                            <li>Sprawdź, czy wpisałeś poprawny email</li>
                                            <li>Sprawdź, czy wpisałeś poprawne hasło</li>
                                            <li>Sprawdź, czy nie masz włączonego caps lock</li>
                                        </ul>
                                    </div>
                                )}
                                <div className="text-center flex-1 flex flex-col items-center mt-7">
                                    <button
                                        className="font__siginika form-submit-button"
                                        type="submit"
                                    >
                                        {login.isLoading ? <Spinner /> : <span>Zaloguj się</span>}
                                    </button>
                                    <div className="flex text-blueGray-600 text-center mt-10 font-bold font__siginika">
                                        Nie masz konta?{' '}
                                        <Link
                                            className="cursor-pointer link ml-1"
                                            to={'/rejestracja'}
                                        >
                                            Zarejestruj się.
                                        </Link>
                                    </div>
                                    <div className="flex text-blueGray-600 text-center font-bold font__siginika">
                                        Zapomniałeś hasła?{' '}
                                        <Link
                                            className="cursor-pointer link ml-1"
                                            to={'/zapomnialem-hasla'}
                                        >
                                            Ustaw nowe hasło.
                                        </Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="bg-image bg-image--login" />
            </div>
        </div>
    );
};

export default LoginPage;

import React from 'react';
import { useFetchMe } from 'src/hooks/react-query/userQueries';
import cx from 'classnames';
import { useFetchMyPlan } from 'src/hooks/react-query/pricingQueries';
import LoadingProfile from 'src/pages/Profile/LoadingProfile';
import { useNavigate } from 'react-router-dom';

const Profile: React.FC = () => {
    const me = useFetchMe();
    const myPlan = useFetchMyPlan();
    const navigate = useNavigate();

    if (me.isLoading) {
        return <LoadingProfile />;
    }

    if (me.isSuccess) {
        return (
            <>
                {/*<LoadingProfile />*/}
                <div className="h-full flex justify-center py-10 px-4 ">
                    <div className="custom-container w-full flex flex-1">
                        <div className="profile-container">
                            <div className="account rounded-t mb-0 px-10 py-10">
                                <span className="card__header text-center">
                                    {me.data.first_name
                                        ? `Witaj, ${me.data.first_name}! Jesteś zalogowana(-y)`
                                        : 'Witaj! Jesteś zalogowana(-y)'}
                                </span>
                                <div className="profile-content">
                                    <div className="flex items-center mt-5">
                                        <span className="mr-3.5 self-center justify-self-start text-xl text-blueGray-600">
                                            Aktualny plan:
                                        </span>
                                        <div>
                                            <span className="mr-2 text-xl font-semibold">
                                                {me.data.is_premium
                                                    ? 'Dla zaawansowanych'
                                                    : 'Dla początkujących'}{' '}
                                            </span>
                                            <div
                                                className={cx(
                                                    'text-xl self-center uppercase inline-flex items-center justify-center rounded badge ',
                                                    {
                                                        'from-blue-premium-500 to-violet-premium-500 bg-gradient-to-r':
                                                            me.data.is_premium
                                                    }
                                                )}
                                            >
                                                <p className="text-center badge__text">
                                                    {me.data.is_premium ? 'Premium' : 'Basic'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center mt-3">
                                        <span className="mr-3.5 self-center justify-self-start text-xl text-blueGray-600">
                                            Plan ważny do:
                                        </span>
                                        <span className="justify-self-start font-bold text-xl">
                                            {me.data.is_premium
                                                ? me.data?.premium_until
                                                : 'Na zawsze'}
                                        </span>
                                    </div>

                                    {!me.data.is_premium && (
                                        <button
                                            className="flex self-start premium-button mt-5"
                                            id="premium-button"
                                            onClick={() => navigate('/cennik')}
                                        >
                                            Przejdź na premium
                                        </button>
                                    )}
                                </div>
                                <div className="flex justify-center mt-5">
                                    {me.data.is_premium && (
                                        <span
                                            onClick={async () => {
                                                // @ts-ignore
                                                await myPlan.mutateAsync();
                                            }}
                                            className="text-blueGray-600 text-blueGray-600 underline scale cursor-pointer mr-5"
                                        >
                                            Zarządzaj subskrybcją
                                        </span>
                                    )}
                                    <a
                                        href="https://goodwrite.pl/polityka-prywatnosci/"
                                        target="_blank"
                                        className="text-blueGray-600 text-blueGray-600 underline scale mr-5"
                                    >
                                        Polityka prywatności
                                    </a>
                                    <a
                                        href="https://goodwrite.pl/regulamin/"
                                        target="_blank"
                                        className="text-blueGray-600 underline scale"
                                    >
                                        Regulamin
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="bg-image bg-image--profile" />
                    </div>
                </div>
            </>
        );
    }
    return <></>;
};

export default Profile;

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { RegistrationSchema } from 'src/consts/schemas';

import { useRegister } from 'src/hooks/react-query/authQueries';
import TextInput from 'src/components/forms/TextInput';
import cx from 'classnames';
import Spinner from 'src/components/Spinner';

const RegistrationPage: React.FC = () => {
    const register = useRegister();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            first_name: '',
            email: '',
            password: '',
            confirmPassword: '',
            acceptTermsAndConditions: false,
            newsletter: false
        },
        initialErrors: {
            first_name: '',
            email: '',
            password: '',
            confirmPassword: '',
            acceptTermsAndConditions: '',
            newsletter: ''
        },
        onSubmit: async ({ email, password, first_name, newsletter, acceptTermsAndConditions }) => {
            formik.setStatus({ _form: '' });
            try {
                await register.mutateAsync({
                    email,
                    password,
                    first_name,
                    newsletter,
                    acceptTermsAndConditions
                });
                navigate('/logowanie');
            } catch (error) {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.detail &&
                    typeof error.response.data.detail === 'string'
                ) {
                    formik.setStatus({ _form: 'error' });
                    switch (error.response.data.detail) {
                        case 'REGISTER_USER_ALREADY_EXISTS':
                            formik.setErrors({
                                email: 'Ups… Ten adres e-mail już jest w naszej bazie.'
                            });
                            break;
                        default:
                            formik.setStatus({ _form: 'error' });
                            break;
                    }
                } else {
                    formik.setStatus({ _form: 'error' });
                }
            }
        },
        validationSchema: RegistrationSchema,
        validateOnBlur: true,
        validateOnChange: false,
        enableReinitialize: true
    });

    const isFormError = () => {
        if (formik.status && formik.status._form && formik.status._form === 'error') {
            return true;
        }
        return false;
    };

    return (
        <div className="h-full flex py-10 px-4 justify-center">
            <div className="custom-container flex flex-1">
                <div className="page-container">
                    <div
                        className={cx('card card--registration', {
                            'card--error': isFormError()
                        })}
                    >
                        <div className="rounded-t mb-0 px-4 py-10">
                            <div className="text-center">
                                <span className="card__header">Rejestracja</span>
                            </div>
                        </div>
                        <div className="flex-auto px-10 pb-10">
                            <form
                                onSubmit={formik.handleSubmit}
                                className="flex items-center flex-col"
                            >
                                <TextInput
                                    value={formik.values['first_name']}
                                    id="first_name"
                                    type={'text'}
                                    placeholder={'Wpisz imię'}
                                    name={'first_name'}
                                    handleChange={formik.handleChange}
                                    isError={!!formik.errors.first_name || isFormError()}
                                    errorMessage={formik.errors.first_name}
                                />
                                <TextInput
                                    value={formik.values['email']}
                                    id={'email'}
                                    type={'email'}
                                    placeholder={'Wpisz email'}
                                    name={'email'}
                                    handleChange={formik.handleChange}
                                    isError={!!formik.errors.email || isFormError()}
                                    errorMessage={formik.errors.email}
                                    classnames="mt-7"
                                />
                                <TextInput
                                    value={formik.values['password']}
                                    id={'password'}
                                    type={'password'}
                                    placeholder={'Wpisz hasło'}
                                    name={'password'}
                                    handleChange={formik.handleChange}
                                    isError={!!formik.errors.password || isFormError()}
                                    errorMessage={formik.errors.password}
                                    classnames="mt-7"
                                />
                                <TextInput
                                    value={formik.values['confirmPassword']}
                                    id={'confirmPassword'}
                                    type={'password'}
                                    placeholder={'Powtórz hasło'}
                                    name={'confirmPassword'}
                                    handleChange={formik.handleChange}
                                    isError={!!formik.errors.confirmPassword || isFormError()}
                                    errorMessage={formik.errors.confirmPassword}
                                    classnames="mt-7"
                                />

                                <div className="w-full">
                                    <label className="mt-7 inline-flex items-center">
                                        <input
                                            type="checkbox"
                                            className="form-checkbox"
                                            checked={formik.values.acceptTermsAndConditions}
                                            name={'acceptTermsAndConditions'}
                                            onChange={formik.handleChange}
                                        />
                                        <span className="ml-2 text-sm">
                                            * Akceptuję{' '}
                                            <a
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.open('https://goodwrite.pl/regulamin/');
                                                }}
                                                href="#"
                                                className="text-blue-500 underline scale"
                                                target="_blank"
                                            >
                                                regulamin
                                            </a>{' '}
                                            oraz{' '}
                                            <a
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.open(
                                                        'https://goodwrite.pl/polityka-prywatnosci/'
                                                    );
                                                }}
                                                href="#"
                                                className="text-blue-500 underline scale"
                                                target="_blank"
                                            >
                                                politykę prywatności
                                            </a>{' '}
                                            GoodWrite.
                                        </span>
                                    </label>
                                    <small className="text-red-500 inline-block ml-1">
                                        {formik.errors && formik.errors.acceptTermsAndConditions}
                                    </small>
                                </div>

                                <div className="w-full">
                                    <label className="inline-flex">
                                        <input
                                            type="checkbox"
                                            className="form-checkbox mt-1"
                                            checked={formik.values.newsletter}
                                            name={'newsletter'}
                                            onChange={formik.handleChange}
                                        />
                                        <span className="ml-2  text-sm">
                                            Tak, chcę otrzymywać newsletter o ofertach specjalnych i
                                            dostępnych kuponach rabatowych.
                                        </span>
                                    </label>
                                </div>
                                {/*ml-16*/}
                                <div className="w-full ml-13">
                                    <span className="text-gray-400 text-sm">*Wymagane zgody</span>
                                </div>
                                {isFormError() && (
                                    <div className="w-full flex-1 flex-col mt-5">
                                        <span className="text-danger">Houston mamy problem!</span>
                                    </div>
                                )}
                                <div className="text-center mt-7">
                                    <button
                                        className="form-submit-button"
                                        type="submit"
                                        disabled={register.isLoading}
                                    >
                                        {register.isLoading ? (
                                            <Spinner />
                                        ) : (
                                            <span>Załóż konto</span>
                                        )}
                                    </button>
                                </div>
                            </form>
                            <div className="flex text-blueGray-600 text-center mt-10 font-bold font__siginika justify-center">
                                Masz już konto?{' '}
                                <Link className="cursor-pointer link ml-1" to={'/logowanie'}>
                                    Zaloguj się.
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-image bg-image--register" />
            </div>
        </div>
    );
};

export default RegistrationPage;

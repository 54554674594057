import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from 'src/assets/images/nav_logo.png';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/store';
import { setLoggedIn } from 'src/store/slices/authSlice';
import { useQueryClient } from 'react-query';

interface NavProps {
    isLoggedIn: boolean;
}

const Nav: React.FC<NavProps> = ({ isLoggedIn }) => {
    const queryClient = useQueryClient();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();

    const login = () => {
        navigate('/');
    };

    const logout = () => {
        localStorage.removeItem('GW_TOKEN');
        queryClient.removeQueries('user');
        dispatch(setLoggedIn(false));
    };

    const handleLoginLogout = () => {
        if (isLoggedIn) {
            return logout();
        } else {
            return login();
        }
    };

    const toggleMenu = () => {
        setOpen((prev) => !prev);
    };

    const isLinkActive = (pathNames: string[]) => {
        return pathNames.includes(location.pathname);
    };

    return (
        <nav className="flex items-center justify-center flex-wrap p-2 shadow">
            <div className="custom-container flex flex-1 items-center">
                <div className="flex flex-1 items-center justify-between flex-wrap">
                    <div className="flex items-center flex-shrink-0 text-white mr-6">
                        <Link to={{ pathname: 'https://goodwrite.pl' }} target="_blank">
                            <img src={logo} className="relative h-11" />
                        </Link>
                    </div>
                    <div className={cx('block flex-grow flex sm:hidden')}>
                        <div className="text-sm flex-grow"></div>
                        <button
                            className="flex items-center px-3 py-2 border rounded"
                            onClick={toggleMenu}
                        >
                            <svg
                                className="fill-current h-3 w-3"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <title>Menu</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                            </svg>
                        </button>
                    </div>
                    <div
                        className={cx(
                            'w-full block flex-grow sm:flex sm:items-center sm:w-auto nav:py-4 nav:pl-2',
                            {
                                'nav:hidden': !open
                            }
                        )}
                    >
                        <div className="text-sm sm:flex-grow"></div>
                        <Link
                            to={'/cennik'}
                            className={cx([
                                'nav:block nav-item',
                                isLinkActive(isLoggedIn ? ['/cennik'] : ['/cennik'])
                                    ? 'text-blue-500'
                                    : 'text-black-400'
                            ])}
                        >
                            Cennik
                        </Link>
                        {isLoggedIn && (
                            <Link
                                to={'/profil'}
                                className={cx([
                                    'nav:block nav:mt-3 nav:mb-3 nav-item',
                                    isLinkActive(['/profil', '/'])
                                        ? 'text-blue-500'
                                        : 'text-black-400'
                                ])}
                            >
                                Profil
                            </Link>
                        )}
                        {!isLoggedIn ? (
                            <Link
                                onClick={handleLoginLogout}
                                to={isLoggedIn ? '/' : '/logowanie'}
                                className={cx([
                                    'nav:block nav-item',
                                    isLinkActive(['/', '/logowanie'])
                                        ? 'text-blue-500'
                                        : 'text-black-400'
                                ])}
                            >
                                Logowanie
                            </Link>
                        ) : (
                            <Link
                                onClick={handleLoginLogout}
                                to={isLoggedIn ? '/' : '/logowanie'}
                                className={cx(['nav-item'])}
                            >
                                Wyloguj
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </nav>

        // <nav className="flex items-center justify-center flex-wrap py-2 px-4 shadow">
        //     <div className="custom-container flex flex-1">
        //         <div className="flex items-center flex-shrink-0 text-white mr-6">
        //             <img src={logo} alt={'logo'} className="relative h-11" />
        //         </div>
        //
        //         <div className={cx({}, 'w-full block flex-grow flex items-center w-auto ')}>
        //             <div className="text-sm flex-grow"></div>
        //             <Link
        //                 to={'/cennik'}
        //                 className={cx([
        //                     'nav-item',
        //                     isLinkActive(isLoggedIn ? ['/cennik', '/'] : ['/cennik'])
        //                         ? 'text-blue-500'
        //                         : 'text-black-400'
        //                 ])}
        //             >
        //                 Cennik
        //             </Link>
        //             {isLoggedIn && (
        //                 <Link
        //                     to={'/profil'}
        //                     className={cx([
        //                         'nav-item',
        //                         isLinkActive(['/profil']) ? 'text-blue-500' : 'text-black-400'
        //                     ])}
        //                 >
        //                     Profil
        //                 </Link>
        //             )}
        //             {!isLoggedIn ? (
        //                 <Link
        //                     onClick={handleLoginLogout}
        //                     to={isLoggedIn ? '/' : '/logowanie'}
        //                     className={cx([
        //                         'nav-item',
        //                         isLinkActive(['/', '/logowanie'])
        //                             ? 'text-blue-500'
        //                             : 'text-black-400'
        //                     ])}
        //                 >
        //                     Logowanie
        //                 </Link>
        //             ) : (
        //                 <Link
        //                     onClick={handleLoginLogout}
        //                     to={isLoggedIn ? '/' : '/logowanie'}
        //                     className={cx(['nav-item'])}
        //                 >
        //                     Wyloguj
        //                 </Link>
        //             )}
        //         </div>
        //     </div>
        // </nav>
    );
};

export default Nav;

export const LOCAL_STORAGE = Object.freeze({
    token: 'GW_TOKEN'
});

export const PRICING_OPTIONS = {
    BASIC: [
        { text: 'Zaawansowana korekta językowa (w zakresie pisowni i gramatyki)', isBold: true },
        {
            text: 'Inteligentnie rozpoznawanie błędów wynikających z szybkiego pisania',
            isBold: false
        },
        {
            text: 'Dodatek do przeglądarek Google Chrome oraz Mozilla Firefox',
            isBold: false
        }
    ],
    PREMIUM: [
        { text: 'Zaawansowana korekta językowa (w zakresie pisowni i gramatyki)', isBold: true },
        {
            text: 'Inteligentne rozpoznawanie błędów wynikających z szybkiego pisania',
            isBold: false
        },
        { text: 'Dodatek do przeglądarki Google Chrome', isBold: false },
        { text: 'Wbudowany słownik synonimów', isBold: false },
        { text: 'Funkcjonalna korekta stylistyczna', isBold: false },
        { text: 'Dodatek do przeglądarki Mozilla Firefox', isBold: false, comingSoon: true },
        { text: 'Ocena zrozumiałości tekstu', isBold: false, comingSoon: true },
        { text: 'Rozpoznawanie wyrażeń wulgarnych i nieetycznych', isBold: false, comingSoon: true }
    ],
    TEAM: [
        { text: 'Dostęp do wszystkich funkcjonalności planu dla zaawansowanych', isBold: true },
        {
            text: 'Możliwość zakupu pakietów grupowych dla użytkowników z Twojej organizacji',
            isBold: false
        },
        {
            text: 'dostęp do API:',
            options: ['poprawy tekstu', 'synonimów', 'kreatywnych sugestii'],
            isBold: false
        },

        {
            text: 'Dostęp do statystyk i raportów wykorzystania',
            isBold: false
        },
        {
            text: 'Jedna faktura dla wszystkich użytkowników',
            isBold: false
        },
        {
            text: 'Szybkie wdrożenie i stała obsługa techniczna',
            isBold: false
        }
    ]
};

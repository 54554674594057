import React from 'react';
import PricingCard from 'src/components/pricing/PricingCard';
import { PRICING_OPTIONS } from 'src/consts/consts';
import Premium from 'src/components/pricing/Premium';
import { Products } from 'src/interfaces/Pricing';
import { BADGE_TYPE } from 'src/helpers/consts';
import ForTeams from 'src/components/pricing/ForTeams';
import Basic from 'src/components/pricing/Basic';
import { useFetchMe } from 'src/hooks/react-query/userQueries';
import { useSelector } from 'react-redux';
import { AppState } from 'src/store';

interface CardDeck {
    products: Products[];
}

const CardDeck: React.FC<CardDeck> = ({ products }) => {
    const me = useFetchMe();
    const isLoggedIn = useSelector((state: AppState) => state.auth.isLoggedIn);

    return (
        <>
            <PricingCard
                key={'Basic'}
                isActive={(isLoggedIn && !me.data?.is_premium) || false}
                logoBadgeText={BADGE_TYPE.BASIC}
                header="Dla początkujących"
                headerCaption="Idealny dla tych, którzy redagują teksty nieoficjalne (e-maile lub komentarze i wiadomości w mediach społecznościowych i portalach internetowych)."
                options={PRICING_OPTIONS.BASIC}
            >
                <Basic isPremium={me.data?.is_premium} />
            </PricingCard>
            <PricingCard
                key={'Premium'}
                isActive={(isLoggedIn && me.data?.is_premium) || false}
                header="Dla zaawansowanych"
                logoBadgeText={BADGE_TYPE.PREMIUM}
                headerCaption="Stworzony z myślą o tych, którzy na co dzień pracują ze słowem pisanym: marketerów, PR-owców, dziennikarzy, redaktorów portali newsowych."
                options={PRICING_OPTIONS.PREMIUM}
            >
                <Premium me={me.data} prices={products[0].prices} />
            </PricingCard>
            <PricingCard
                key={'Teams'}
                isActive={false}
                header="Dla biznesu"
                logoBadgeText={BADGE_TYPE.PREMIUM}
                headerCaption="Najlepszy dla tych, którzy chcą, wprowadzić swoje narzędzia i aplikacje na nowe tory, dodając do nich API GoodWrite."
                options={PRICING_OPTIONS.TEAM}
            >
                <ForTeams header="Ceny ustalane indywidualnie" />
            </PricingCard>
        </>
    );
};

export default CardDeck;

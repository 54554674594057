import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

const CookiesManager: React.FC = () => {
    const [cookies, setCookie] = useCookies(['gw-panel-accept-cookie-usage']);
    const [isOpen, setIsOpen] = useState(cookies['gw-panel-accept-cookie-usage'] !== 'true');

    const handleAgreement = () => {
        setIsOpen(false);
        setCookie('gw-panel-accept-cookie-usage', true);
    };

    if (isOpen) {
        return (
            <section className="custom-container p-5 bottom-0 bg-gray-400 rounded m-auto left-0 right-0 fixed">
                <div className="md:flex items-center -mx-3">
                    <div className="md:flex-1 px-3 mb-5 md:mb-0">
                        <p className="text-center md:text-left text-white text-xs leading-tight md:pr-12 text-blueGray-600">
                            Ta strona używa plików cookies w celu korzystania z narzędzi
                            analitycznych, marketingowych i społecznościowych. Jeżeli twoje
                            ustawienia nie blokują cookies, wyrażasz zgodę na ich wykorzystywanie.
                            Możesz określić zasady korzystania z cookies w swojej przeglądarce
                        </p>
                    </div>
                    <div className="px-3 text-center">
                        <button
                            id="btn"
                            className="py-2 px-8 bg-orange-500 hover:bg-orange-600 text-white rounded font-bold text-sm shadow-xl mr-3"
                            onClick={handleAgreement}
                        >
                            Rozumiem
                        </button>
                        <button
                            id="btn"
                            className="py-2 px-8 bg-primary-500 hover:bg-primary-600 text-white rounded font-bold text-sm shadow-xl"
                            onClick={() =>
                                window.open('https://goodwrite.pl/polityka-prywatnosci/ ')
                            }
                        >
                            Polityka prywatności
                        </button>
                    </div>
                </div>
            </section>
        );
    }
    return null;
};

export default CookiesManager;

import React from 'react';

const LoadingPricingCard: React.FC = () => {
    return (
        <div className="pricing-card animate-pulse">
            <div className="pricing-card__header">
                <div className="grid grid-cols-pricing-logo items-center gap-x-3">
                    <div className="rounded-full bg-blue-400 h-12 w-12"></div>
                    <div className="h-4 bg-blue-400 rounded w-3/4"></div>
                </div>
                <div className="h-4 bg-blue-400 rounded"></div>
                <div className="h-4 bg-blue-400 rounded w-5/6"></div>
            </div>
            <div className="pricing-card__content">
                <div className="h-7 bg-blue-400 rounded"></div>
                <button className="h-7 bg-blue-400 rounded"></button>
                <button className="h-7 bg-blue-400 rounded"></button>
            </div>
            <div className="pricing-card__footer grid gap-y-3.5 pt-3.5">
                <div className="h-4 bg-blue-400 rounded"></div>
                <div className="h-4 bg-blue-400 rounded w-5/6"></div>
                <div className="h-4 bg-blue-400 rounded"></div>
                <div className="h-4 bg-blue-400 rounded w-5/6"></div>
                <div className="h-4 bg-blue-400 rounded"></div>
                <div className="h-4 bg-blue-400 rounded w-5/6"></div>
            </div>
        </div>
    );
};

export default LoadingPricingCard;

import * as yup from 'yup';

export const LoginSchema = yup.object().shape({
    email: yup
        .string()
        .email('Proszę wpisać poprawny adres email')
        .required('Adres email jest wymagany'),
    password: yup.string().required('Hasło jest wymagane')
});

export const RegistrationSchema = yup.object().shape({
    first_name: yup.string().required('Imię jest wymagane'),
    email: yup
        .string()
        .email('Proszę wpisać poprawny adres email')
        .required('Adres email jest wymagany'),
    password: yup
        .string()
        .required('Hasło jest wymagane')
        .min(8, 'Hasło musi się składać minimalnie z 8 znaków'),
    confirmPassword: yup
        .string()
        .required('To pole jest wymagane')
        .oneOf([yup.ref('password'), null], 'Hasła muszą się zgadzać'),
    acceptTermsAndConditions: yup.bool().oneOf([true], 'To pole jest wymagane')
});

export const ResetPassSchema = yup.object().shape({
    password: yup
        .string()
        .required('Hasło jest wymagane')
        .min(8, 'Hasło musi się składać minimalnie z 8 znaków'),
    confirmPassword: yup
        .string()
        .required('To pole jest wymagane')
        .oneOf([yup.ref('password'), null], 'Hasła muszą się zgadzać')
});

export const ForgotPassSchema = yup.object().shape({
    email: yup
        .string()
        .email('Proszę wpisać poprawny adres email')
        .required('Adres email jest wymagany')
});

import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// @ts-ignore

const useBackgroundModal = (param: string, successModal: () => void, failureModal: () => void) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramStatus = queryParams.get(param);
    useEffect(() => {
        if (paramStatus === 'success') {
            successModal();
        } else if (paramStatus === 'failure') {
            failureModal();
        }
        queryParams.delete(param);
        navigate({
            search: queryParams.toString()
        });
    }, []);
};

export default useBackgroundModal;

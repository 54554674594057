import React from 'react';
import { FormikHandlers } from 'formik';
import { Price } from 'src/interfaces/Pricing';
import { polishPlurals } from 'polish-plurals';

interface PricingSelect {
    label: string;
    options: Price[];
    selectedPricing: Price;
    handleChange: FormikHandlers['handleChange'];
}

const PricingSelect: React.FC<PricingSelect> = ({
    label,
    options,
    handleChange,
    selectedPricing
}) => {
    const intervalString = (interval: string | number) => {
        const modulo = Number(interval) % 12 === 0;
        if (modulo) {
            return `Co ${Number(interval) / 12} ${polishPlurals(
                'Rok',
                'Lat',
                'Lata',
                Number(interval) / 12
            )}`;
        } else {
            if (interval === 1) {
                return `${polishPlurals('miesięcznie', 'miesiące', 'miesięcy', Number(interval))}`;
            } else {
                return `Co ${interval} ${polishPlurals(
                    'miesiąc',
                    'miesiące',
                    'miesięcy',
                    Number(interval)
                )}`;
            }
        }
    };

    return (
        <div className="relative border-solid border rounded-lg border-light-gray p-3">
            <label>
                <span className="text-black-400 text-small pr-3">{label}</span>
                <span className="text-black-500 text-small font-bold">
                    {intervalString(selectedPricing.interval)}
                </span>
                <i className="fas fa-caret-down select-icon"></i>
                <select name={'price_id'} className="custom-select" onChange={handleChange}>
                    {options.map((option) => {
                        return (
                            <option
                                key={option.id}
                                value={option.id}
                                label={intervalString(option.interval)}
                            />
                        );
                    })}
                </select>
            </label>
        </div>
    );
};

export default PricingSelect;

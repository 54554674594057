import { useFormik } from 'formik';
import React from 'react';
import { useForgotPassword } from 'src/hooks/react-query/authQueries';
import cx from 'classnames';
import TextInput from 'src/components/forms/TextInput';
import { ForgotPassSchema } from 'src/consts/schemas';

const ForgotPasswordPage = () => {
    const forgotPassword = useForgotPassword();
    const formik = useFormik({
        initialValues: {
            email: ''
        },
        onSubmit: async (values) => {
            try {
                const { email } = values;
                await forgotPassword.mutateAsync(email);
            } catch (error) {
                formik.setStatus({ _form: 'error' });
            }
        },
        validationSchema: ForgotPassSchema,
        validateOnBlur: true,
        validateOnChange: false,
        enableReinitialize: true
    });

    const isFormError = () => {
        if (formik.status && formik.status._form && formik.status._form === 'error') {
            return true;
        }
        return false;
    };

    return (
        <div className="h-full flex py-48 px-4 justify-center">
            <div className="custom-container flex flex-1">
                <div className="page-container">
                    <div
                        className={cx('card card--login', {
                            'card--login-error': isFormError()
                        })}
                    >
                        <div className="rounded-t mb-0 px-4 py-10">
                            <div className="text-center">
                                <span className="card__header">Odzyskiwanie hasła</span>
                            </div>
                            <div className="flex text-blueGray-600 text-center mt-10 font__siginika">
                                Podaj adres e-mail, który wykorzystałaś(-eś) przy rejestracji. Na
                                Twoją skrzynkę wyślemy link do strony umożliwiającej zmianę hasła.
                            </div>
                        </div>

                        <div className="flex-auto px-10 pb-10">
                            <form
                                className="flex items-center flex-col"
                                onSubmit={formik.handleSubmit}
                            >
                                <div className="w-full flex-1 flex flex-col ">
                                    <TextInput
                                        value={formik.values.email}
                                        id={'email'}
                                        type={'text'}
                                        placeholder={'Wpisz e-mail'}
                                        name={'email'}
                                        handleChange={formik.handleChange}
                                        isError={!!formik.errors.email || isFormError()}
                                        errorMessage={formik.errors.email}
                                        classnames="mt-1"
                                    />
                                </div>

                                {forgotPassword.isSuccess && (
                                    <div className="w-full">
                                        <span className="text-xs text-green-500 inline-block ml-1">
                                            Wysłaliśmy e-mail z linkiem do resetowania hasła.
                                        </span>
                                    </div>
                                )}
                                <button type="submit" className="form-submit-button mt-5">
                                    Zmień hasło
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="bg-image bg-image--login" />
            </div>
        </div>
    );
};

export default ForgotPasswordPage;

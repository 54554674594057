import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { userApi } from 'src/api/api';
import { FetchMeResponse } from 'src/interfaces/auth/FetchMeInterfaces';

export const useFetchMe = (): UseQueryResult<FetchMeResponse, AxiosError> => {
    return useQuery(
        ['user'],
        async () => {
            const response = await userApi.fetchMe();
            return response.data;
        },
        {
            staleTime: Infinity,
            retry: false,
            cacheTime: 0
        }
    );
};
